@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$darkPurple: #3C0555; // Primary
$mediumPurple: #610F7F; // Link
$middlePurple: #814299; // Secondary
$lightPurple: #A880B9; // Success
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-light: $beige-light;
$primary: $darkPurple;
$link: $mediumPurple;
$success: $lightPurple;
$info: $middlePurple;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/components/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/utilities/_all.sass';
